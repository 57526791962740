import App from 'tools/app';
import SignIn from 'components/team_members/sign_in'
import FlashMessage from "@smartweb/vue-flash-message";

App.init(() => {
  if (document.getElementById("flash-messages") !== null) {
    new App.Vue({
      el: "#flash-messages",
      data() {
        return {
          flash: []
        };
      },
      components: { "flash-messages": FlashMessage },
      mounted() {
        App.Vue.set(this.$data, "flash", JSON.parse(this.$el.dataset.flash));
        this.$data.flash.forEach(msg => {
          this.flashMessage[msg[0]](msg[1]);
        });
      }
    });
  }
  new App.Vue({
    el: '#sign-in',
    components: {
      SignIn
    }
  })
})
