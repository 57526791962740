<template>
  <div class="sign-in sign-in__container">
    <div class="sign-in__form">
      <div class="sign-in__form-header">
        Login
      </div>
      <FlashMessage class="z-index-on-top"></FlashMessage>
      <div class="sign-in__form-content">
        <div class="row mh5 mt20">
          <cInput
            required
            email
            label="email address"
            v-model.trim="$v.team_member.email.$model"
            :invalid="$v.team_member.email.$error"
          />
        </div>
        <div class="row mh5 mt20">
          <cInput
            required
            type="password"
            label="password"
            v-model.trim="$v.team_member.password.$model"
            :invalid="$v.team_member.password.$error"
          />
        </div>
        <div class="sign-in__actions">
          <cCheckbox
            name="remember_me"
            label="Remember me"
            v-model="team_member.remember_me"
          />
          <a class="sign-in__recovery" @click="redirectRecovery">Forgot Password</a>
        </div>
        <div class="flex jcenter mh5 mt30 pb30">
          <cButton :disabled="preventMultiSubmit" @click="log_in" style="width: 40%">Login</cButton>
        </div>
      </div>
    </div>
    <div class="sign-in__corner acenter">
      <span class="mr5 description"> Don't have an account yet?</span>
      <cButton white @click="redirectTrial">Start Free Trial</cButton>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue/dist/vue.esm'
  import Vuelidate from "vuelidate";
  import Routes from 'packs/routes'
  import {required, email} from 'vuelidate/lib/validators';
  import { isPassword } from "tools/validations";

  Vue.use(Vuelidate);
  export default {
    name: "sign-in",
    props: {
      loggedOut: Boolean
    },
    data() {
      return {
        team_member: {
          email: '',
          password: '',
          remember_me: false
        },
        preventMultiSubmit: false
      }
    },
    mounted(){
      if(this.loggedOut){
        this.flashMessage.success({title: 'Logged out', message: 'You have been logged out'});
      }
    },
    methods: {
      log_in(){
        this.preventMultiSubmit = true;
        this.$http.post(Routes.sign_in_path(), { team_member: this.team_member }).then(
          () => {
            window.location.href = Routes.root_path()
          },
          () => {
            this.flashMessage.error({title: 'Could not log in', message: 'Please verify your credentials'});
            this.preventMultiSubmit = false;
          }
        )
      },
      redirectTrial(){
        window.location.href = Routes.new_onboarding_company_path()
      },
      redirectRecovery(){
        window.location.href = Routes.new_password_path()
      }
    },
    computed: {},
    validations: {
      team_member: {
        email: { required, email },
        password: { required, isPassword }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sign-in{
    display: flex;
    flex-direction: row;
    @include media("<=phone"){
      flex-direction: column;
      & > :nth-child(1){
        order: 2;
        width: 90%;
      }
      & > :nth-child(2){
        order: 1;
        width: 90%;
        align-self: center;
        justify-content: space-between;
        padding: 0px;
      }
    }
    &__container{
      height: 100%;
      display: flex;
      justify-content: center;
      width: 100%;
      @include media("<=desktop"){
        padding-right: 0px;
        width: 100%;
      }
      @include media(">=tablet", "<desktop"){
        width: 85%;
      }
      @include media("<=phone"){
      }
    }
    &__form{
      align-self: center;
      padding: 10px 0px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      height: fit-content;

      @include media(">=desktop"){
        width: 35%;
      }
      @include media(">phone", "<desktop"){
        width: 55%;
      }
      @include media("<=phone"){
        margin: 0px;
      }
    }
    &__actions{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin: 20px 5px;
      @include media("<=phone"){
        flex-direction: column;
      }
      & > :nth-child(2){
        @include media("<=phone"){
          margin-top: 15px;
        }
      }
    }

    &__form-header{
      @include headline1;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 25px;
      @include media("<=phone"){
        @include headline2;
        justify-content: center;
      }
    }
    &__form-content{
      display: flex;
      flex-direction: column;
      padding: 0px 10px;
      margin-top: 10px;
    }
    &__recovery{
      text-decoration: underline;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }
    &__corner{
      padding: 5px 5px 10px 5px;
      max-height: 90px;
      display: flex;
      flex: 0 1 35%;
      border-radius: 6px;
      @include media("<=desktop"){
        flex: 0 1 15%;
      }
      @include media(">phone", "<=desktop"){
        flex-direction: column;
        margin-left: 10px;
      }
      @include media("<=phone"){
        margin: 10px 0px;
        align-self: center;
        width: 90%;
      }

    }
  }
</style>
