import { helpers } from "vuelidate/lib/validators";

export const isPhone = nr =>
  !helpers.req(nr) || /\(\d{3}\) \d{3}-\d{4}/.test(nr);
export const isZipCode = nr =>
  !helpers.req(nr) || /^[0-9]{5}(?:-[0-9]{4})?$/.test(nr);
export const isPassword = pass =>
  !helpers.req(pass) ||
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*?]{8,}$/.test(pass);
export const isPrice = pass =>
  !helpers.req(pass) || /^\d+(.\d{1,2})?$/.test(pass);
export const inPercentageRange = sum => value =>
  Number(value) + Number(sum) <= 100;
